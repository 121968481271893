import './webpack-entry';
import { PluginManifest, PluginStore } from 'graylog-web-plugin/plugin';

import AppConfig from 'util/AppConfig';

import Routes from './o365/common/Routes';
import O365InputConfiguration from './o365/O365InputConfiguration';
import o365App from './o365/O365App';
import EmbeddedO365App from './o365/EmbeddedO365App';
import GmailApp from './gmail/GmailApp';
import gmailRoutes from './gmail/common/Routes';
import GmailInputConfiguration from './gmail/GmailInputConfiguration';
import EmbeddedGmailApp from './gmail/EmbeddedGmailApp';
import WorkspaceApp from './googleworkspace/WorkspaceApp';
import workspaceRoutes from './googleworkspace/common/Routes';
import WorkspaceInputConfiguration from './googleworkspace/WorkspaceInputConfiguration';
import EmbeddedWorkspaceApp from './googleworkspace/EmbeddedWorkspaceApp';
import GCPApp from './gcp/GCPApp';
import Route from './gcp/common/Routes';
import GCPInputConfiguration from './gcp/GCPInputConfiguration';
import EmbeddedGCPApp from './gcp/EmbeddedGCPApp';
import AzureApp from './azure/AzureApp';
import AzureRoutes from './azure/common/Routes';
import AzureLogsInputConfiguration from './azure/AzureLogsInputConfiguration';
import EmbeddedAzureApp from './azure/EmbeddedAzureApp';
import ScriptNotificationDetails from './script-notifications/ScriptNotificationDetails';
import ScriptNotificationForm from './script-notifications/ScriptNotificationForm';
import ScriptNotificationSummary from './script-notifications/ScriptNotificationSummary';
import LDAPAdapterFieldSet from './components/adapters/ldap/LDAPAdapterFieldSet';
import LDAPAdapterSummary from './components/adapters/ldap/LDAPAdapterSummary';
import LDAPAdapterDocumentation from './components/adapters/ldap/LDAPAdapterDocumentation';
import URLhausAdapterFieldSet from './components/adapters/urlhaus/URLhausAdapterFieldSet';
import URLhausAdapterSummary from './components/adapters/urlhaus/URLhausAdapterSummary';
import URLhausAdapterDocumentation from './components/adapters/urlhaus/URLhausAdapterDocumentation';
import ThreatFoxAdapterFieldSet from './components/adapters/threatfox/ThreatFoxAdapterFieldSet';
import ThreatFoxAdapterSummary from './components/adapters/threatfox/ThreatFoxAdapterSummary';
import ThreatFoxAdapterDocumentation from './components/adapters/threatfox/ThreatFoxAdapterDocumentation';
import { GreyNoiseMultiAdapterDocumentation, GreyNoiseMultiAdapterFieldSet, GreyNoiseMultiAdapterSummary } from './components/adapters/greynoise';

import packageJson from '../../package.json';

const manifest = new PluginManifest(packageJson, {
  routes: [
    { path: Routes.INTEGRATIONS.O365.ACTIVITYAPI.index, component: o365App },
    { path: gmailRoutes.INTEGRATIONS.Gmail.ACTIVITYAPI.index, component: GmailApp},
    { path: workspaceRoutes.INTEGRATIONS.Workspace.ACTIVITYAPI.index, component: WorkspaceApp},
    { path: Route.INTEGRATIONS.GCP.ACTIVITYAPI.index, component: GCPApp},
    { path: AzureRoutes.INTEGRATIONS.Azure.ACTIVITYAPI.index, component: AzureApp},
  ],
  inputConfiguration: [
    {
      type: 'org.graylog.enterprise.integrations.office365.Office365Input',
      component: O365InputConfiguration,
      embeddedComponent: EmbeddedO365App,
    },
    {
      type: 'org.graylog.enterprise.integrations.gmail.GmailInput',
      component: GmailInputConfiguration,
      embeddedComponent: EmbeddedGmailApp,
    },
    {
      type: 'org.graylog.enterprise.integrations.googleworkspace.WorkspaceInput',
      component: WorkspaceInputConfiguration,
      embeddedComponent: EmbeddedWorkspaceApp,
    },
    {
      type: 'org.graylog.enterprise.integrations.gcp.GCPInput',
      component: GCPInputConfiguration,
      embeddedComponent: EmbeddedGCPApp,
    },
    {
          type: 'org.graylog.enterprise.integrations.azure.AzureLogsInput',
          component:AzureLogsInputConfiguration,
          embeddedComponent: EmbeddedAzureApp,
    },
  ],
  lookupTableAdapters: [
    {
      type: 'LDAP',
      displayName: 'Active Directory User Lookup',
      formComponent: LDAPAdapterFieldSet,
      summaryComponent: LDAPAdapterSummary,
      documentationComponent: LDAPAdapterDocumentation,
    },
    {
      type: 'GreyNoise Lookup [Enterprise]',
      displayName: 'GreyNoise Full IP Lookup [Enterprise]',
      formComponent: GreyNoiseMultiAdapterFieldSet,
      summaryComponent: GreyNoiseMultiAdapterSummary,
      documentationComponent: GreyNoiseMultiAdapterDocumentation,
    },
    {
      type: 'urlhaus',
      displayName: 'URLhaus Malware Distribution URL Lookup [Enterprise]',
      formComponent: URLhausAdapterFieldSet,
      summaryComponent: URLhausAdapterSummary,
      documentationComponent: URLhausAdapterDocumentation,
    },
    {
      type: 'threatfox',
      displayName: 'ThreatFox IOC Lookup [Enterprise]',
      formComponent: ThreatFoxAdapterFieldSet,
      summaryComponent: ThreatFoxAdapterSummary,
      documentationComponent: ThreatFoxAdapterDocumentation,
    },
  ],
  eventNotificationTypes: AppConfig.isCloud() ? [] : [
    {
      type: 'script-notification-v1',
      displayName: 'Script Notification [Enterprise]',
      formComponent: ScriptNotificationForm,
      summaryComponent: ScriptNotificationSummary,
      detailsComponent: ScriptNotificationDetails,
      defaultConfig: ScriptNotificationForm.defaultConfig,
    },
  ],
});

PluginStore.register(manifest);
